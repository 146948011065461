<template>
  <div class="member-list-popup" :class="{ 'minimized': isMinimized }" :style="popupStyle">
    <!-- Header -->
    <div class="member-list-header d-flex justify-content-between align-items-center p-1" @click="toggleMinimize">
      <div class="d-flex align-items-center">
        <span class="font-weight-bold">
          {{ $t('chat.messages') }}
          <b-badge variant="primary" pill>
            {{ members.length }}
          </b-badge>
        </span>
      </div>
      <div class="chat-controls">
        <b-button variant="link" class="p-0 mr-1">
          <feather-icon :icon="!isMinimized ? 'MinusIcon' : 'MaximizeIcon'" size="16" class="toggle-icon" />
        </b-button>
      </div>
    </div>

    <!-- Search and Member List -->
    <div v-show="!isMinimized" class="member-list-content">
      <!-- Search -->
      <div class="search-box px-1 py-50" v-if="this.members && this.members.length > 5">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" size="14" />
          </b-input-group-prepend>
          <b-form-input v-model="searchQuery" :placeholder="$t('chat.search-members')" class="search-input" />
        </b-input-group>
      </div>

      <!-- Members List -->
      <app-scroll class="members-scroll-area">
        <template v-if="filteredMembers.length">
          <div v-for="member in filteredMembers" :key="member.key" class="member-item d-flex align-items-center p-1"
            @click="openChat(member)">
            <user-avatar :user="member" size="32" class="mr-1" />
            <div class="member-info flex-grow-1">
              <h6 class="mb-0">{{ member.name }} {{ member.surname }}</h6>
              <small class="text-muted">{{ member.headline || '--' }}</small>
            </div>
          </div>
        </template>

        <!-- No Results Found -->
        <div v-else class="no-results d-flex flex-column align-items-center justify-content-center p-4">
          <feather-icon icon="SearchIcon" size="40" class="text-muted mb-2" />
          <h6 class="text-muted mb-1">{{ $t('chat.no-results') }}</h6>
          <small class="text-muted">
            {{ $t('chat.try-different-search') }}
          </small>
        </div>
      </app-scroll>
    </div>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BFormInput, BButton } from 'bootstrap-vue';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import AppScroll from '@core/components/app-scroll/AppScroll.vue';
import { CHAT_ACTIONS } from '@/views/apps/chat/constants/chat-store-constants';

export default {
  name: 'ChatListPopup',
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    UserAvatar,
    AppScroll,
  },
  data() {
    return {
      isMinimized: true,
      searchQuery: '',
      members: [],
    };
  },
  computed: {
    filteredMembers() {
      const query = this.searchQuery.toLowerCase();
      return this.members.filter(member =>
        member.name.toLowerCase().includes(query) ||
        member.surname.toLowerCase().includes(query) ||
        (member.headline && member.headline.toLowerCase().includes(query))
      );
    },
    popupStyle() {
      const position = window.innerWidth <= 768 ? '0px' : '20px';
      return {
        right: position,
        width: window.innerWidth <= 480 ? '100%' : '280px'
      };
    }
  },
  async created() {
    await this.fetchMembers();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
    },
    async fetchMembers() {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'communityMembers',
        page: 1,
        forceAPICall: true,
        perPage: 100,
        storedKey: 'members',
        communitySlug: this.$store.getters.currentCollective.slug,
        requestConfig: {
          visibleOncommunityKey: this.$store.getters.currentCollective.key,
        },
      });
      this.members = response.data;
    },
    openChat(member) {
      const room = `${this.$store.getters.currentCollective.slug}-${parseInt(this.$store.getters.loggedMember.uniqueNumber) +
        parseInt(member.uniqueNumber, 10)
        }`;
      this.$store.commit('chat/SET_ACTIVE_CHAT', room);
      this.$store.dispatch(CHAT_ACTIONS.openChatWindow, { user: member, room });
    },
    handleResize() {
      this.$forceUpdate();
    }
  },
};
</script>

<style lang="scss" scoped>
.member-list-popup {
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 280px;
  height: 455px;
  background: white;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  flex-direction: column;

  &.minimized {
    height: 48px;
  }

  .member-list-header {
    background: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    border-radius: 8px 8px 0 0;
    height: 48px;
    cursor: pointer;
  }

  .member-list-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .search-box {
    border-bottom: 1px solid #dee2e6;
  }

  .members-scroll-area {
    flex: 1;
    overflow-y: auto;
  }

  .member-item {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f8f9fa;
    }
  }

  .member-info {
    h6 {
      font-size: 0.9rem;
    }

    small {
      font-size: 0.75rem;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 768px) {
    border-radius: 8px 8px 0 0;

    &.minimized {
      width: 200px;
      right: 0;
    }
  }

  @media (max-width: 480px) {
    right: 0;
    left: 0;
    width: 100%;

    &.minimized {
      width: 150px;
    }
  }

  .chat-controls {
    button {
      color: #6c757d;
      transition: transform 0.2s ease;

      &:hover {
        color: #495057;
      }

      .toggle-icon {
        transition: transform 0.2s ease;
      }
    }
  }

  &.minimized {
    .chat-controls button .toggle-icon {
      transform: rotate(180deg);
    }
  }

  .no-results {
    height: 100%;
    min-height: 200px;
    text-align: center;

    .feather-icon {
      opacity: 0.5;
    }

    h6 {
      font-size: 1rem;
    }

    small {
      font-size: 0.875rem;
    }
  }
}
</style>